import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/musicResource";

export default class MusicRepository {
    async index(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexMusics"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async stageSongsindex(data, stageId) {
        const params = setQuery(data);
        const response = await axios.get(url("stageSongsindex", { stageId: stageId }), { params });
        if (response.status === 200) {
            return response.data;
        }
    }

    async show(musicId) {
        const response = await axios.get(url("showMusic", { music: musicId }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }

    async store(data) {
        const json = setData(data);
        const response = await axios.post(url("storeMusic"), json);
        if (response.status === 201) {
            console.log(response);
            return getJson(response.data);
        }
    }

    async update(musicId, data) {
        const json = setData(data);
        const response = await axios.put(url("updateMusic", { music: musicId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(musicId) {
        const response = await axios.put(url("updateSortMusic", { music: musicId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(musicId) {
        await axios.delete(url("destroyMusic", { music: musicId }));
    }
}
