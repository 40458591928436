import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    name_ar: data.name_ar,
    description: data.description,
    lyric: data.lyric,
    genre_id: data.genre_id,
    artist_id: data.artist?.id,
    link: data.link,
    share_link: data.share_link,
    share_count: data.share_count,
    length: data.length,
    exclusive: !!data.exclusive,
    played_count: data.played_count,
    fake_played: data.fake_played,
    panel_played_count: data.panel_played_count,
    total_played: data.total_played,
    genre: data.genre
        ? {
              id: data.genre.id,
              name: data.genre.name,
              name_ar: data.genre.name_ar,
              subject: data.genre.subject,
              subject_ar: data.genre.subject_ar,
              image: data.genre.image,
              thumbnail: data.genre.thumbnail,
          }
        : null,
    artist: data.artist
        ? {
              id: data.artist.id,
              name: data.artist.name,
              bio: data.artist.bio,
              playedCount: data.artist.playedCount,
              name_ar: data.artist.name_ar,
              first_name_length: data.artist.first_name_length,
              first_name: data.artist.first_name,
              last_name: data.artist.last_name,
              email: data.artist.email,
              email_verified_at: data.artist.email_verified_at,
              is_artist: data.artist.is_artist,
              cell_number: data.artist.cell_number,
              city_id: data.artist.city_id,
              country_id: data.artist.country_id,
              genre_id: data.artist.genre_id,
              image: data.artist.image,
              thumbnail: data.artist.thumbnail,
          }
        : null,
    image: data.image,
    thumbnail: data.thumbnail,
    hasFavorite: data.hasFavorite,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((user) => getJson(user));
    return { data, pagination };
};
export const setData = (data) => {
    const formData = {
        name: data.name,
        name_ar: data.name_ar,
        description: data.description,
        lyric: data.lyric,
        exclusive: data.exclusive,
        genre_id: data.genre_id,
        artist_id: data.artist_id,
        played_count: data.played_count,
        length: data.length,
        link: data.link,
        share_link: data.share_link,
        file_batch_id: data.file_batch_id,
        fake_played: data.fake_played,
    };
    return formData;
};
export const setQuery = (data) => SetQueriesObject(data);
