import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, setData, getJson,
} from '../resources/radioStationResource'

export default class RadioStationRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexRadioStation'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(id) {
        const response = await axios.get(url('showRadioStation', { radioStation: id }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeRadioStation'), json)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async updateSort(radioStationId) {
        const response = await axios.put(
            url('updateSortRadioStation', { radioStation: radioStationId }),
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async update(id, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateRadioStation', { radioStation: id }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(id) {
        const response = await axios.delete(url('destroyRadioStation', { radioStation: id }))
        if (response.status === 200) {
            return true
        }
    }


}
