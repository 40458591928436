import axios from 'axios'
import url from '../url'
import { getJson, setData } from '../resources/fileResource'

export default class FileRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async store(data) {
        const formData = setData(data)
        const response = await axios.post(url('storeFile'), formData)
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async destroy(fileId) {
        const response = await axios.delete(url('destroyFile', { file: fileId }))
        if (response.status === 200) {
            return true
        }
    }
}
