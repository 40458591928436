import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/newsResource'

export default class NewsRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexNews'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(newsId) {
        const response = await axios.get(url('showNews', { news: newsId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeNews'), json)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async update(newsId, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateNews', { news: newsId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async updateSort(newsId) {
        const response = await axios.put(
            url('updateSortNews', { news: newsId }),
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(newsId) {
        await axios.delete(url('destroyNews', { news: newsId }))
    }

}
