import { debounce } from 'lodash'

export default (e, callback) => {
    debouncedFunction(e, callback)
}

let debouncedFunction = debounce((search, callback) => {
    debouncedFunction.cancel()
    if (search && search.trim().length > 1) {
        callback()
    }
}, 400)
