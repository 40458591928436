import { SetPagination, SetQueriesObject } from '@/utils/setQueriesObject'

export const getJson = data => ({
    id: data.id,
    name: data.name,
    name_ar: data.name_ar,
    link: data.link,
    live: !!data.live,
    music: data.music,
    image: data.image,
    thumbnail: data.thumbnail,
})
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta)
    data = data.map(radioStation => getJson(radioStation))
    return { data, pagination }
}
export const setData = data => ({
    name: data.name,
    name_ar: data.name_ar,
    live: data.live,
    link: data.link,
    file_batch_id: data.file_batch_id,
})
export const setQuery = data => SetQueriesObject(data)
