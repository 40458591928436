import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/playListResource'

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexAdminPlaylist'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(PlayListId) {
        const response = await axios.get(url('showAdminPlaylist', { playlist: PlayListId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async storeWithSlug(data) {
        const json = setData(data)
        const response = await axios.post(url('storeWithSlogAdminPlaylist', { slug: json.slug }), json)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }
    async convert(data) {
        const response = await axios.post(url('convertAdminPlayList'), data)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }
    async update(id, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateAdminPlaylist', { playlist: id }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(
            url('updateSortAdminPlaylist', { playlist: playlistId }),
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(id) {
        const response = await axios.delete(url('deletePlaylist', { playlist: id }))
        if (response.status === 200) {
            return true
        }
    }

    async attach(playlistId, musicId) {
        const json = {music_id:musicId};
        const response = await axios.post(
            url('attachAdminPlaylist', { playlist: playlistId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async detach(playlistId, musicId) {
        const json = {music_id:musicId};
        const response = await axios.post(
            url('detachAdminPlaylist', { playlist: playlistId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }
}
