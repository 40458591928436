import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    title: data.title,
    description: data.description,
    video_link: data.video_link,
    published_at: data.published_at,
    created_at: data.created_at,
    share_count: data.share_count,
    image: data.image,
    thumbnail: data.thumbnail,
    exclusive: !!data.exclusive,
    user: data.user
        ? {
              id: data.user.id,
              name: data.user.name,
              first_name: data.user.first_name,
              last_name: data.user.last_name,
              thumbnail: data.user.thumbnail,
          }
        : {},
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((news) => getJson(news));
    return { data, pagination };
};
export const setData = (data) => {
    const formData = {
        title: data.title,
        description: data.description,
        exclusive: data.exclusive,
        video_link: data.video_link,
        published_at: data.published_at,
        file_batch_id: data.file_batch_id,
    };
    console.log(formData);
    return formData;
};
export const setQuery = (data) => SetQueriesObject(data);
